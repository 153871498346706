@import 'variables.scss';

.HeaderBar {

    margin-top: 1em;

    @media screen and (max-width: $tablet - 1) {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }

    // ensure that the contents are vertically-aligned
    //
    .columns {
        align-items: center;
    }

    .logo {
        text-align: left;

        // tweak the size & margins on smaller devices
        //
        @media screen and (max-width: $tablet - 1) {
            width: 50%;
            margin: 0;
            text-align: left;

            img {
                height: 32px;
            }
        }
    }

    // style the phone number link
    //
    .phone-number {

        // align to the right
        //
        text-align: right;

        @media screen and (max-width: $tablet - 1) {
            // on smaller screens, align to the left & increase margins
            //
            text-align: right;
            padding-left: 1.5em;
            padding-right: 1.5em;
        }

        a {
            white-space: nowrap;
            font-family: MontSerrat;
            font-weight: 600;
            font-size: 20px;
            font-style: normal;
            color: white;

            &:hover {
                color: white;
            }
            @media screen and (max-width: $tablet - 1) {
                font-size: 13px;
            }
        }
    }
}
