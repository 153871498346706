@import 'variables.scss';

@media screen and (max-width: $mobile-width) {

    // tweak the size & spacing on mobile
    //
    .__react_component_tooltip {
        max-width: 70vw;
        margin: 1em;
        padding: 8px;
    }

}
