@import 'variables.scss';
@import 'forms.scss';

div.gsl {

    .form-copy {
        margin-top: 0.5em;
        margin-bottom: 2em;
        font-size: 2em;
    }

    // on desktop, add space above the form elements
    //
    @media screen and (min-width: $desktop) {
        .columns:first-child {
            margin-top: 1.5em;
        }
    }

    // tweak the Google Search + Sales Lead form for mobile
    //
    @media screen and (max-width: $mobile-width) {

        .form-copy {
            margin: 0.5em 0.5em 1em;
            font-size: 1.5em;
        }

        // remove the top margin on the columns
        //
        .columns {
            margin-top: 0;
        }

        // add a top margin to the search element column
        //
        .columns.gsl-search-column {
            margin-top: 1.5em;
        }

        // tweak the padding on each column
        //
        .column {
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }
    }

}
