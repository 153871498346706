@import 'variables.scss';

// the styling for the "Required" asterisk on input elements
// Note that this is also used in the PhoneInput component; at some point I need
// to make this more general
//
.required-asterisk {
    color: $generic-grey;
}

.TextInput {

    // ensure that text is aligned to the left
    //
    text-align: left;

    // tweak the label display
    //
    label.label {
        text-align: left;
        display: inline;
        color: white;
        font-weight: normal;
    }

    // style the help text icon
    //
    span.jam-help {
        color: $generic-grey;
        margin-left: 0.3em;
        cursor: pointer;
    }

    // style the caption (non-error)
    //
    .help.caption {
        color: $generic-grey;
    }

    @media screen and (max-width: $mobile-width) {
        // on smaller screens, align to the left & reset the max-width
        //
        .control {
            text-align: left;

            input {
                max-width: initial;
            }
        }
    }

}
