// import the bulma styles
//
@import 'variables.scss';

.SearchInput {
    // increase the font size in the search input element
    //
    input {
        font-size: $size-4;
        padding: $size-4 / 2;
    }

    // make the "disabled" background a little darker
    //
    input:disabled {
        background-color: lighten($nala-grey, 60%);
    }
}
