@import 'variables.scss';

$small-image-size: 95vw;
$offset: 2vw;
$layered-images-width: 65%;
$layered-images-height: 150px;


$tablet-small-image-size: 80vw;
$tablet-offset: 7vw;
$tablet-layered-images-width: 80%;
$tablet-layered-images-height: auto;

.ImageList {

    .columns {
        position: relative;
    }

    $rotation: -11deg;
    $tablet-rotation: -70deg;
    $mobile-rotation: -70deg;

    div.sample-text {
        position: absolute;
        font-size: 4em;
        color: rgba(0, 0, 0, 0.3);
        font-weight: bold;
        text-align: center;
        width: 100%;
        transform: rotate($rotation);
        z-index: 1000;
        letter-spacing: 22px;

        @media screen and (max-width: $tablet) {
            position: relative;
            top: 3.5em;
            transform: rotate($tablet-rotation);
            width: 70%;
            height: 0;
            font-size: 8em;
            letter-spacing: 30px;
        }

        @media screen and (max-width: $mobile-width) {
            position: relative;
            font-size: 4em;
            top: 5em;
            transform: rotate($mobile-rotation);
            width: 70%;
            height: 0;
        }
    }

    img {
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    }

    .small-example {
        width: $small-image-size;
        @media screen and (max-width: $tablet) {
            width: $tablet-small-image-size;
        }
    }

    .layered-images {
        position: relative;

        min-height: 100px;

        img {

            &:last-child {
                margin-bottom: 1em;
            }

            width: $layered-images-width;

            @media screen and (max-width: $tablet) {
                margin-left: 12.5%;
                width: $tablet-layered-images-width;
            }

            position: absolute;

            &.googlemaps {
                top: 0;
                left: 0;
            }

            &.facebook {
                top: $offset * 1;
                left: $offset * 1;
                @media screen and (max-width: $tablet) {
                    top: $tablet-offset * 1;
                    left: $tablet-offset * 1;
                }
            }

            &.chamberofcommerce {
                top: $offset * 2;
                left: $offset * 2;
                @media screen and (max-width: $tablet) {
                    top: $tablet-offset * 2;
                    left: $tablet-offset * 2;
                }
            }

            &.foursquare {
                top: $offset * 3;
                left: $offset * 3;
                @media screen and (max-width: $tablet) {
                    top: $tablet-offset * 3;
                    left: $tablet-offset * 3;
                }
            }
        }
    }

}