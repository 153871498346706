@import 'variables.scss';

div.AddressDisplay {

    // align the text on the left
    //
    text-align: left;

    // make the address lines appear as blocks - rendering these in spans and
    // setting `display: block` style allows me to turn off the line breaks on
    // mobile
    //
    span.address-line {
        display: block;
    }

    // make the business name bold
    //
    span.name {
        font-weight: bold;
    }

    @media screen and (max-width: $mobile-width) {

        // align the text on the left
        //
        text-align: center;

        // render the address as a single line
        //
        span.address-line {
            // display address lines as inline
            //
            display: inline;

            // display the name, phone number on a separate line
            //
            &.name-phone {
                display: block;
            }
        }
    }

}
