@import 'variables.scss';

$base-font-size: 1em;
$border-radius: 12px;


// .ReactModalPortal
//      .modal-overlay
//          .confirmation-modal

// hide the overflow on the body when the modal is open
//
body.ReactModal__Body--open {
    overflow: hidden;
}

// code to center the modal was taken from
// https://stackoverflow.com/a/23682546/2008384. The classes defined in that
// answer are added as comments below.
//
// Note that this code uses flexbox and, as such, supports only IE 10 & 11.
//
.modal-overlay { // .parent
    width: 100vw;
    height: 100vh;

    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    overflow-y: hidden;
}

.confirmation-modal { // .centered
    width: 500px;
    height: 260px;
}


.confirmation-modal {

    // turn off the outline when focussed
    //
    &:focus { outline-style: unset; }

    // remove any border
    //
    border:none;

    // add a drop shadow
    //
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.5);

    // set the default text color
    //
    color: black;

    // adjust the basic button style
    //
    button {
        text-transform: uppercase;
        border-radius: 0;
    }

    // adjust the correction button
    //
    button.correction-button {
        color: white;
        background-color: #74277f;
        &:hover {
            background-color: $rdm-blue;
        }
    }

    // style the "back" link
    //
    .back-link {
        color: white;
        display: inline-block;
        padding-top: 0.4em;
        padding-left: 0.5em;
    }

    .address1, .address2, .city-state-zip {
        line-height: 1.3em;
    }

    .phone { display: block; font-style: italic; }

    .modal-header {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        background-color: $rdm-blue;
        color: white;
        padding: 0.9em;

        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-align: center;
        font-weight: bold;
        font-size: $base-font-size * 1.1;
        .modal-headline {
        }
    }

    .modal-body {
        background: $background-gradient-horizontal;
        color: white;

        .confirmation-modal-message {
            padding: 1.5em 2em;
            font-size: $base-font-size;



            article {
                background-color: white;

                .message-body {
                    border-width: 0 0 0 12px;
                }
            }

            @media screen and (max-width: $mobile-width) {

                padding: 1em 1em;

                article {
                    margin: 0.25em 0.25em 1em;

                    .message-body {
                        border-width: 0 0 0 4px;
                        padding: 0.5em;
                    }
                }
            }
        }
    }

    .modal-footer {
        display: inline-block;
        width: 100%;

        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        background-color: $rdm-purple;

        padding: 0.9em 0.5em;

        text-align: right;
        button { margin-right: 1em; }

        min-height: 64px;
    }

    max-width: 500px;
    height: 260px;


    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: $border-radius;

    .browser { display: inherit; }
    .mobile { display: none; }

    // responsive
    // On small screens, adjust the modal to take up the whole screen and adjust
    // the font sizes to fit
    //
    @media screen and (max-width: $mobile-width) {
        color: white;

        box-shadow: none;

        background: $modal-background;

        font-size: $base-font-size;

        .browser { display: none; }
        .mobile { display: initial; }

        .modal-body {
            background: $modal-background;
            text-align: center;
            font-size: $base-font-size;

            // turn off address, city, state, zip on mobile
            //
            .address-line.address1 { display: block; }
            .address-line.address2 { display: block; }
            .address-line.city-state-zip { display: block; }

            // remove the leading comma from the address2 line
            //
            .address-line.address2:before {
                content: none;
            }
        }

        .modal-header {
            border-radius: 0;
            font-size: $base-font-size * 1.2;
            padding-bottom: 1em;
        }

        .modal-footer {
            background: none;
            border-radius: 0;

            padding-top: 1em;

            .button-column {
                text-align: center;

                a {
                    display: block;
                    width: 290px;
                }
            }

            button {
                font-size: $base-font-size * 0.9;
                margin: 0;
                margin-top: 1em;
                width: 290px;
            }
        }

        .back-link {
            display: block;
            padding-top: 1em;
        }

        // center the contents of the modal vertically
        //
        display: flex;
        flex-direction: column;
        justify-content: center;

        // reset the modal to take up the whole screen
        //
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: none;
        border-radius: 0;
        width: 100vw;
        height: 100vh;
        max-width: 100vw;
        max-height: 100vh;

        // remove padding
        //
        padding: 0;

        // remove the top margin on the columns
        //
        .columns, .column {
            margin: 0;
            padding: 0;
        }

    }
}

// style the modal overlay
//
.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.5);
}
