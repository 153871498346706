@import 'variables.scss';

@import '~bulma/bulma.sass';

// remove the ad tracking pixel image from the CSS flow so it doesn't mess up my
// box model
//
img[width="1"][height="1"] {
    position: absolute;
}

html {
    background-color: #3a072b;
    overflow-y: auto;

    &.no-vertical-scroll {
        overflow-y: hidden;
    }

    &.page-scan {
        body {
            overflow-y: hidden;
            height: initial;
        }
    }
}

body {
    background: $background-gradient;
    color: #0073b0;

    // width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    &.page-scan {
        height: initial;
    }
}

.App {
    text-align: center;
    color: white;

    // responsive: add a tiny bit of padding on left & right
    //
    @media screen and (max-width: $mobile-width) {
        .app-contents {
            // padding: 0 0.25em;
        }
    }

}

div.logo-image {
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    // responsive: shrink logo image and hide the "Search for your business"
    // message
    //
    @media screen and (max-width: $mobile-width) {
        padding-top: 0.5em;
        padding-bottom: 0;

        img {
            width: 48px;
        }

        p {
            display: none;
        }

    }
}

div.thank-you {
    height: 99vh;
    .title {
        margin-top: 5em;
        margin-bottom: 2em;
        color: white;
        font-size: 2em;
        @media screen and (max-width: $tablet) {}
        @media screen and (max-width: $mobile-width) {
            margin: 10vh 0.5em;
        }
    }

    p {
        font-size: 1.5em;
        @media screen and (max-width: $tablet) {}
        @media screen and (max-width: $mobile-width) {
            margin: 5vh 0.5em;
        }
    }
}

// adjust the continue button
//
button.is-primary.continue-button {
    // transform text to uppercase
    //
    text-transform: uppercase;

    // remove border radius
    //
    border-radius: 0;

    // set the colors
    //
    color: black;
    background-color: yellow;

    // on hover, change the background color
    //
    &:hover {
        background-color: #1cbb45;
    }

    // on disabled, grey everything out
    //
    &:disabled {
        background-color: lighten(yellow, 80%);
        color: lighten(black, 80%);
    }
}

.scan-positioning {

    iframe {
        width: 100vw;
        height: 100vh;
    }
}

.scan-positioning-outer {

    .scan-positioning-inner {

        position: relative;

        // the scan results iframe styles
        //
        iframe {
            position: absolute;
            top: 79px;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 0;

            height: $sites-3-col-iframe-height;
            @media screen and (max-width: $sites-2-col) {
                height: $sites-2-col-iframe-height;
            }
            @media screen and (max-width: $sites-1-col) {
                top: 28px;
                height: $sites-1-col-iframe-height;
            }
            @media screen and (max-width: $sites-mobile-col) {
                top: 12px;
                height: $sites-mobile-col-iframe-height;
            }
        }

        div.clear-section {
            width: 100%;
            z-index: 500;

            height: $sites-3-col-clear-height;
            @media screen and (max-width: $sites-2-col) {
                height: $sites-2-col-clear-height;
            }
            @media screen and (max-width: $sites-1-col) {
                height: $sites-1-col-clear-height;
            }
            @media screen and (max-width: $sites-mobile-col) {
                height: $sites-mobile-col-clear-height;
            }

        }

        div.fade-overlay-top {
            position: relative;
            z-index: 500;

            background-image: linear-gradient(
                to bottom,
                rgba(2,32,103, 0),
                rgba(#4f093b, 1)
            );

            width: 100%;

            height: $sites-3-col-gradient-height;
            @media screen and (max-width: $sites-2-col) {
                height: $sites-2-col-gradient-height;
            }
            @media screen and (max-width: $sites-1-col) {
                height: $sites-1-col-gradient-height;
            }
            @media screen and (max-width: $sites-mobile-col) {
                height: $sites-mobile-col-gradient-height;
            }
        }

        div.fade-overlay-bottom {
            color: white;
            position: relative;
            background-color: #3a072b;
            z-index: 501;
        }

    }

}