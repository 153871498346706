@import 'variables.scss';

div.fsl {

    // remove spacing between business name, phone and address lines
    //
    .business-name-phone {
        margin-bottom: 0;
    }
    // tweak the Google Search + Sales Lead form for mobile
    //
    @media screen and (max-width: $mobile-width) {

        // add a little space at the bottom
        //
        margin-bottom: 0.5em;

        // reset margins, padding on columns & fields
        //
        .columns, .column, .field {
            margin: 0;
            padding: 0;
        }

        // add a small amount of space at the bottom of fields
        //
        .field { margin-bottom: 0.5em; }

        // add some space at the bottom of the sales lead input section
        //
        .columns.sales-lead {
            margin-bottom: 1em;
        }

        // add some space under the bottom button
        //
        button {
            margin-bottom: 1em;
        }
    }

}
