@import 'variables.scss';

.ScanResultsMessage {

    display: flex;
    justify-content: space-between;
    width: $sites-3-col-copy-width;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: $sites-2-col) {
        width: $sites-2-col-copy-width;
    }
    @media screen and (max-width: $sites-1-col) {
        width: 100vw;
        display: block;
    }
    @media screen and (max-width: $sites-mobile-col) {}
    @media screen and (max-width: $tablet) {}
    @media screen and (max-width: $mobile-width) {}


    .message-section {
        text-align: center;
        width: 50%;
        padding: 2em;
        flex-grow: 1;

        @media screen and (max-width: $sites-2-col) {}
        @media screen and (max-width: $sites-1-col) {
            display: block;
            width: 100vw;
        }
        @media screen and (max-width: $sites-mobile-col) {}
    }

    .listings-message {
        background-color: #212730;
        color: white;

        @media screen and (max-width: $sites-2-col) {}
        @media screen and (max-width: $sites-1-col) {}
        @media screen and (max-width: $sites-mobile-col) {}

        p {
            &.title {
                font-size: 1.5em;
                color: white;
                padding-top: 1em;
                padding-bottom: 1em;
                font-weight: normal;

                @media screen and (max-width: $sites-2-col) {}
                @media screen and (max-width: $sites-1-col) {
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-bottom: 0.35em;
                    font-size: 28px;
                }
                @media screen and (max-width: $sites-mobile-col) {
                    font-size: 18px;
                }
            }

            font-size: 1.2em;
            padding: 0;
            text-align: left;
            margin: 0 auto;
            margin-bottom: 0.3em;

            @media screen and (max-width: $sites-2-col) {}
            @media screen and (max-width: $sites-1-col) {
                font-size: 20px;
            }
            @media screen and (max-width: $sites-mobile-col) {
                font-size: 14px;
            }
        }
    }

    .call-to-action {
        background-color: #e1ecdb;
        color: black;
        padding-top: 1.3em;

        @media screen and (max-width: $sites-2-col) {}
        @media screen and (max-width: $sites-1-col) {
            margin-right: 0;
        }
        @media screen and (max-width: $sites-mobile-col) {}

        h1 {
            font-size: 1.75em;
            margin-top: 0.5em;
            margin-bottom: 1em;

            @media screen and (max-width: $sites-2-col) {}
            @media screen and (max-width: $sites-1-col) {
                font-size: 1.25em;
            }
            @media screen and (max-width: $sites-mobile-col) {}
        }

        p {
            @media screen and (max-width: $sites-2-col) {}
            @media screen and (max-width: $sites-1-col) {
                font-size: 0.5em;
            }
            @media screen and (max-width: $sites-mobile-col) {}
        }

        button.full-report {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            background-color: yellow;
            border-color: transparent;
            color: black;
            font-weight: bold;

            @media screen and (max-width: $sites-2-col) {}
            @media screen and (max-width: $sites-1-col) {}
            @media screen and (max-width: $sites-mobile-col) {
                font-size: 1.25em;
            }
        }
    }

}