@import 'variables.scss';

div.form {

        // center align all the controls
        //
        div.control {
            text-align: center;
        }

        // tweak the Google Search + Sales Lead form for tablet size screens
        // (less than 769px)
        //
        @media screen and (max-width: $tablet - 1) {
            div.field {
                margin-right:1.5em;
                margin-left: 1.5em;
            }
        }

}
