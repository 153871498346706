@import 'variables.scss';

// The search results list
//
.search-results {

    // set the background & foreground colours
    //
    background-color: $search-results-background;
    color: black;

    // tweak the positioning of the list
    //
    margin-top: 0;
    margin-right: 2em;
    margin-left: 2em;

    // add a border-radius
    //
    border-radius: 8px;

    // add some padding to the "select your business" and "can't find your
    // business?" messages
    //
    p {
        padding: 0.5em;
    }

    // show the browser message, hide the mobile message
    //
    span.browser { display: inline; };
    span.mobile {
        display: none;
        font-size: 0.8rem;
    };

    // responsive tweaks for the search results element
    //
    @media screen and (max-width: $mobile-width) {

        // tweak the positioning of the list
        //
        margin-top: -1em;

        // hide the "Select your business below" message
        //
        p.select-message { display: none; }

        // tweak the size of the "can't find your business?" link message
        //
        p.link-message {
            padding-top: 0;
            padding-bottom: 0.2rem;
        }

        // show the mobile message, hide the browser message
        //
        span.browser { display: none };
        span.mobile { display: inline };

    }

    // style the results list
    //
    ul {
        // set up automatic vertical scrollbar
        //
        overflow-y: auto;

        // add a top border to the first list item
        //
        li:first-child {
            border-top: 1px solid $grey-light;
        }

        // add the bottom border & padding to all list items
        //
        li {
            padding: 0.5em;
            border-bottom: 1px solid $grey-light;

            // turn off padding on p elements inside the list elements
            //
             p {
                 padding: 0;
             }
        }

        // add the "hover" pseudo-class to the list items
        //
        li.search-results-item:hover {
            background-color: darken($search-results-background, 4%)
        }

        // tweak the styles for mobile
        //
        @media screen and (max-width: $mobile-width) {

            // set the top margin to move it down under the search input element
            //
            margin-top: 1.25em;
            // reduce the list item font size
            //
            li {
                font-size: 0.8rem;
            }

            // remove the top border to the first list item - this is because
            // the "select your business" message is hidden, so the top border
            // is not needed
            //
            li:first-child {
                border-top: none;
            }

            // ensure that there's a space before the city name
            span.city:before {
                content: ' ';
            }

            li.search-results-item {
                // turn off the zip code on mobile
                //
                .zip { display: none; }

                // turn off address, city, state, zip on mobile
                //
                .address-line.address1 { display: none; }
                .address-line.address2 { display: none; }
                .address-line.city-state-zip { display: none; }
            }

        }

    }

}
