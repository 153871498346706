// font declarations
//
@import url('https://fonts.googleapis.com/css?family=Lato|Montserrat&display=swap');
$family-sans-serif: Lato, BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

// color definitions
//
$background-gradient-top: #022067;
$background-gradient-bottom: #C61894;
$background-gradient: linear-gradient($background-gradient-top, $background-gradient-bottom);
$background-gradient-horizontal: linear-gradient(155deg, $background-gradient-top, $background-gradient-bottom);
$background-gradient-reverse: linear-gradient($background-gradient-bottom, $background-gradient-top);
$search-results-background: lighten($background-gradient-top, 75%);

$rdm-blue: rgba(2,32,103,1);
$rdm-purple: rgba(198,24,148,1);
$nala-grey: #333;
$nala-yellow: #fce287;

$warning: yellow;

$modal-background: $rdm-blue;

// a generic grey used to de-emphasize elements on the forms (eg. "require"
// asterisks & help icons)
//
$generic-grey: #999;

// $modal-background: aliceblue;

// the space around the confirmation modal
//
$position: 20px;

// the breakpoint for mobile browser detection
//
$mobile-width: 420px;

// the max width for input elements
//
$input-max-width: 250px;

$overlay-clear-section-height: 300px;
$overlay-overlap-height: 0;
// $overlay-overlap-height: 0.5%;

$iframe-height: 623px;

$overlay-gradient-overlap: 180px;
$overlay-gradient-section-height: 80px;

// The sizes below are based on the Scan report as it appears in the iframe
// The scan report changes layout depending on width. There are three layouts:
// 3, 2, or 1 column of results based on width (larger width to smaller).
//
// sizes for the 3-column scan results (where width >= 1200px)
//
$sites-3-col-header-height: 79px;
$sites-3-col-gradient-height: 80px;
$sites-3-col-iframe-height: 650px;
$sites-3-col-clear-height: $sites-3-col-iframe-height - $sites-3-col-gradient-height - 45px;
$sites-3-col-copy-width: 1160px;

// sizes for the 2-column scan results (where 992px <= width <= 1199px)
//
$sites-2-col: 1199px;
$sites-2-col-header-height: 79px;
$sites-2-col-gradient-height: 80px;
$sites-2-col-iframe-height: 790px;
$sites-2-col-clear-height: $sites-2-col-iframe-height - $sites-2-col-gradient-height - 50px;
$sites-2-col-copy-width: 960px;

// sizes for the 1-column scan results (where width <= 991px)
//
$sites-1-col: 991px;
$sites-1-col-header-height: 79px;
$sites-1-col-gradient-height: 80px;
$sites-1-col-iframe-height: 930px;
$sites-1-col-clear-height: $sites-1-col-iframe-height - $sites-1-col-gradient-height - 30px;
$sites-1-col-copy-width: 740px;


// sizes for the 1-column scan results on mobile (where width <= $mobile-width)
// $mobile-width = 420px
//
$sites-mobile-col: $mobile-width;
$sites-mobile-col-header-height: 79px;
$sites-mobile-col-gradient-height: 80px;
$sites-mobile-col-iframe-height: 1320px;
$sites-mobile-col-clear-height: $sites-mobile-col-iframe-height - $sites-mobile-col-gradient-height - 40px;
$sites-mobile-col-copy-width: 312px;

// import the bulma styles
//
@import '~bulma/sass/utilities/_all';
