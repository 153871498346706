@import 'variables.scss';

// set the spinner size
//
$Spinner-size: 64px;

.Spinner {
    // add some space around it
    //
    margin: 3em 1.5em;

    // set the image size
    //
    img {
        width: $Spinner-size;
        height: $Spinner-size;
    }
}
